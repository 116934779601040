import {createApp} from 'vue'
import {createRouter,createWebHistory,createWebHashHistory} from 'vue-router'

export default new createRouter({
	history: createWebHistory(),
	//history: createWebHistory(),
	routes : [{
			path: '/',
			component: () => import ('../template/index.vue'),
			children:[
			]
		},{
			path: '/individual-center',
			component: () => import ('../template/individual-center.vue'),
			children:[
			]
		},{
			path: '/:pathMatch(.*)',
			redirect: '/'
		}
	]
})