<template>
  <div id="app">
	<header>
		<div class="box-nav" v-if="!miniAppShow.pc">
			<ul>
		      <li><a href="#">首页</a></li>
		       <li><a href="https://house.zhusky.com">房源</a></li>
		       <li><a href="#company_profile">关于我们</a></li>
		      <!-- <li><a href="#">联系我们</a></li> -->
		    </ul>
	  </div>
	  <nav v-else>
	    <ul>
	      <li><a href="#">首页</a></li>
	      <li><a href="https://house.zhusky.com">房源</a></li>
	      <li><a href="#company_profile">关于我们</a></li>
		  <li><a href="https://media.zhusky.com/source/files-download/zhuskybot/zhuskybot_0.1.3.exe">软件下载</a></li>
	      <li><a href="/admin">管理员</a></li>
	    </ul>
	  </nav>
	</header>
	
    <router-view></router-view>
	
	<footer class="footer">
		
		<div class="link-to">
			<p>© 2021 筑天下房地产经纪有限责任公司官网. All rights reserved.</p> 
			<p><a target="_blank" href="https://house.zhusky.com">官方三方网</a></p>  
			<p><a target="_blank" href="https://aiqicha.baidu.com/company_detail_86432946957205">企业信息</a></p>
		</div>
		
		<div class="beian">
			<span>
				<a target="_blank" href="https://beian.miit.gov.cn/">浙ICP备2023001898号-1</a>
			</span>
		</div>
	</footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
  	return {
		miniAppShow: {
  			show: false,
  			pc: true
  		} 
	}
  },
  mounted() {
  	if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) this.miniAppShow.pc = false;
  }
}
</script>

<style>
#body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
    }
    header {
      background-color: #333;
      color: #fff;
      padding: 20px;
    }
	.box-nav {
	      display: flex;
	      flex-direction: row;
	      justify-content: space-between;
	      padding: 10px;
	    }
	    .box-nav ul {
	      list-style-type: none;
	      margin: 0;
	      padding: 0;
	    }
	    .box-nav li {
	      display: inline-block;
	      margin-right: 10px;
	    }
	    .box-nav li a {
	      display: block;
	      color: #333;
	      text-decoration: none;
	      padding: 5px 10px;
	      background-color: #f5f5f5;
	      border-radius: 5px;
	    }
    nav ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
    nav li {
      float: left;
    }
    nav li a {
      display: block;
      color: #fff;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
    }
    
    footer {
		position: fixed;
		left: 0px;
		bottom: -16px;
		width: 100%;
		z-index: 9999;
		line-height:25px;
		color:#fff;
		background-color: #333;
		color: #fff;
		padding: 10px;
		text-align: center;
    }
/* .footer { } */
	.link-to,.beian {display: flex;font-size: .7rem;justify-content: center;justify-items: center;}
	.link-to p {margin: 0 .4rem;}
</style>
